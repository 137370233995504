import Button from "@/components/Common/Buttons/Button/Button";
import AboutButton from "@/components/Common/Buttons/AboutButton/AboutButton";
import CheckBox from "@/components/Common/Inputs/CheckBox/CheckBox";
import PrinceRangeSlider from "@/components/Components/PriceRangeSlider/PriceRangeSlider";
import MarketplaceAd from "@/components/Components/MarketplaceAd/MarketplaceAd";
import PropertyAd from "@/components/Components/PropertyAd/PropertyAd";
import MarketplaceSortFilter from "@/views/MarketplaceCategoryPage/MarketplaceSortFilter/MarketplaceSortFilter";


export default {
    name: 'Single Vendor',
    components: {

        Button,
        CheckBox,
        PrinceRangeSlider,
        MarketplaceAd,
        PropertyAd,
        AboutButton,
        MarketplaceSortFilter
    },
    data() {
        return {
            termsBoxActive: false,
            activeDescription: 'delivery',
            translation: {
                general_inforomation: 'Обща информация',
                company: 'Фирма',
                address: 'Адрес',
                company_id: 'ЕИК',
                iban: 'IBAN',
                bank: 'Банка',
                phone: 'Телефонен номер',
                email: 'Email',
                delivery: 'Доставка',
                warranty_conditions: 'Гаранционни условия',
                return_policy: 'Политика за връщане',
                data_protection: 'Защита на личните данни',
            },
            vendor: null
        }
    },
    methods: {
        toggleTermsBox() {
            this.termsBoxActive = !this.termsBoxActive
        }
    },
    async mounted() {
        const id = this.$route.params.id;
        await this.$store.dispatch('vendor/getVendor', id);
        this.vendor = this.$store.state.vendor.current
    },
}
